<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest?'(测试环境,数据定期删除)':''}}当前：所有单据(生成凭证)</strong>
                                    <strong class="pull-right" style=" font-size: 16px;color: red ">{{isBuildIng?'正在凭证，稍后刷新页面查看！':''}}</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="4">
                                <el-date-picker
                                        v-model="pickerDateMonth"
                                        style=" margin-top: 8px;padding-top: 2px; width: 180px"
                                        type="month"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerDateEvent(),searchAllWarehouse()"
                                        :clearable="false"
                                        value-format="yyyy-MM-dd"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份">
                                </el-date-picker>
                                <el-date-picker
                                        v-show="false"
                                        style=" margin-top: 8px; width: 220px"
                                        value-format="yyyy-MM-dd"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        @change="searchAllWarehouse()"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :lg="8" :sm="12" :md="8">
                                <el-select v-model="billCategory" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                           style="width: 100px;margin-right: 10px"
                                           @change="changeBillCategoryEvent"
                                           default-first-option filterable>
                                    <el-option v-for="item in billCategoryList" :key="item.value" :label="item.name"
                                               :value="item.value">
                                        <span>{{ item.name}}</span>
                                    </el-option>
                                </el-select>
                                <el-button v-if="$_getActionKeyListBoolean(['/voucher/batchCreateVoucher'])"
                                           style=" margin-top: 8px; " type="danger" round
                                           :disabled="$refs.xGrid == null || tableDataMain.length == 0"
                                           @click="batchCreateVoucher()" size="mini">批量生成凭证
                                </el-button>
                                <el-button style=" margin-top: 8px; " type="primary" round
                                           @click="searchAllWarehouse" size="mini">搜索
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip'])"
                                           style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="openTheWarehouse($refs.xGrid.getCurrentRecord())" size="mini">修改
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip/lodopPrint'])"
                                           style=" margin-top: 8px; " type="primary" round
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null || $refs.xGrid.getCurrentRecord().bill_id_type =='CNDJ' "
                                           @click="printTransferSlip($refs.xGrid.getCurrentRecord())" size="mini">查看
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>

                            </el-col>
                            <el-col :lg="9" :sm="12" :md="12">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>

                                        <vxe-radio-group @change="searchAllWarehouse()"  v-model="formData.voucherCheck" size="mini" style="margin-left:12px;  margin-top: 8px; " :strict="false">
                                            <vxe-radio-button :label="-1" content="全部"></vxe-radio-button>
                                            <vxe-radio-button :label="0" content="未生成凭证"></vxe-radio-button>
                                            <vxe-radio-button :label="1" content="已生成凭证"></vxe-radio-button>
                                        </vxe-radio-group>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                id="allBillingId"
                                align="center"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-current-row
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :custom-config="{storage:true}"
                                show-footer
                                :cell-class-name="cellClassName"
                                @cell-dblclick="cellClickEvent"
                                :footer-method="footerMethod"
                                :row-class-name="tableRowClassName">
                            <template #operate="{row}">
                                <vxe-button @click="openTheWarehouse(row)">修改</vxe-button>
                                <vxe-button @click="printTransferSlip(row)">查看</vxe-button>
                            </template>
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,10000,20000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                    <vxe-modal v-model="showModel.isShow" width="600" :title="showModel.bill_id">
                        <template #default>
                            <vxe-grid
                                    ref="modalGrid"
                                    show-overflow
                                    auto-resize
                                    height="300"
                                    size="mini"
                                    highlight-current-row
                                    @cell-dblclick="openReceipt($refs.modalGrid.getCurrentRecord())"
                                    :data="tableDataModel"
                                    :columns="tableColumnModel">
                            </vxe-grid>
                        </template>
                    </vxe-modal>
                    <!--batchCreateVoucher()-->
                    <el-dialog width="420px" title="生成凭证" :visible.sync="createVoucher.isShow">
                        <a type="danger" style="margin-bottom: 5px">{{createVoucher.str}}</a>
                        <el-form-item label="从凭证字开始生成:" label-width="140px">
                            <el-input style="width: 100%" size="mini" placeholder="不填自动从最大的凭证号开始生成" v-model="createVoucher.formData.voucher_id"></el-input>
                        </el-form-item>
                        <div slot="footer" class="dialog-footer">
                            <el-button @click="createVoucher.isShow = false">取 消</el-button>
                            <el-button type="primary" @click="$_batchCreateVoucher(true,'',createVoucher.formData.voucher_id)">确 定</el-button>
                        </div>
                    </el-dialog>
                </el-row>

            </el-form>
        </el-main>

    </div>

</template>

<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
           }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                isBuildIng:false,
                billCategory: 'KD',
                billCategoryList: [
                    {
                        value: 'KD',
                        name: '销售开单'
                   }, {
                        value: 'JC',
                        name: '进仓单'
                   }, {
                        value: 'CNDJ',
                        name: '出纳单'
                   },
                ],
                showModel: {
                    bill_id: '',
                    isShow: false,
               },
                type: '',//空代表默认，CN代表出纳用的（外购单一览）
                formData: {//搜索筛选
                    name: null,
                    showCancel: false,
                    voucherCheck:0,//全部 未生成 已生成
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
//visible:false,
                tableColumn: [],
                tableColumnCNDJ: [
                    { field:'cash_type_id', width: 60 ,title: '单号' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'cash_type_name', width: 90 ,title: '单据类型' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'cash_date', width: 95 ,title: '日期',formatter: ({cellValue}) => {return this.$_formatDate(cellValue,'yyyy-MM-dd')} ,slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'id', visible: false,width: 60 ,title: 'id' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'company_name', width: 180 ,title: '往来单位' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'type', width: 80 ,title: '单位类型',visible: false, slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'type_name', width: 80 ,title: '单位类型', slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'subject_id_cash_data',visible : false,  width: 80 ,title: '科目编码' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'subject_name_cash_data', width: 160 ,title: '科目名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'credit_side_name',width: 160 ,title: '贷方科目名称' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'cash_data_remark',visible : true,  width: 150 ,title: '备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                    { field:'money_cash_data', visible : true, formatter: ({cellValue}) => {
                            /*if(cellValue > 0 ){
                                return cellValue
                           }else{
                                return  0 - cellValue
                           }*/
                            return cellValue
                       },width: 95 ,title: '金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'total_balance_money', visible : true, formatter: ({cellValue}) => {
                            return cellValue
                       },width: 95 ,title: '冲账金额' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'total_amount',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                       }, width: 95 ,title: '合计(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'paid_up',visible : false,  formatter: ({cellValue}) => {
                            return cellValue
                       }, width: 95 ,title: '实收(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   }
                    ,{ field:'hang_money',visible : false, width: 95 ,title: '挂数(金额)' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   }
                    ,{ field:'registrar', width: 70 ,title: '记录员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'create_date', width: 170 ,title: '创建日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'last_name_cash',visible : false, width: 90 ,title: '最后修改人' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'last_date_cash', visible : false,width: 120 ,title: '修改日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'re_check_man', visible : false,width: 60 ,title: '复核' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'re_check_man_date',visible : false, width: 120 ,title: '复核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },
                    { field:'check_man',visible : false, width: 70 ,title: '审核员' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'check_man_date',visible : false, width: 120 ,title: '审核日期' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate)
                       }
                   },{ field:'summary_cash_data', width: 170 ,title: '摘要' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'is_cancel_name', width: 60 ,title: '状态' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },{ field:'remark_cash', visible : false, width: 80 ,title: '单据备注' , slots:{
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', { size: 'mini'}, this.filterData, this.sortData,'/admin/allFinance/searchHeard',this.pickerDate,this.formData)
                       }
                   },
                ],
                tableColumnJC: [
                    {
                        field:'id', width: 110,  title: '单据唯一Id', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'bill_type', width: 110, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'bill_id', width: 160, title: '进仓单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'bill_date', width: 100, title: '日期', formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'supplier', width: 220, title: '供应商', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },

                    {
                        field:'total_number', width: 60, title: '支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_weighing_heavy', width: 88, title: '过磅重(吨)', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'total_amount', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                ],
                tableColumnKD: [
                    {
                        field:'id', title: 'ID', width: 50, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'bill_date_kd', width: 100, title: '开单日期', formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },

                    {
                        field:'clearing_form', width: 80, title: '结算方式', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'print_count', width: 60, title: '打印', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'tax_tate_warehouse_bill', width: 55, title: '税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                           }else{
                                return cellValue + '%'
                           }
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'bill_id_kd', width: 160, title: '销售开单-单号', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'sales_man', width: 70, title: '业务员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'customer', width: 240, title: '客户', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'total_amount', width: 80, title: '合计金额', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                   {
                        field:'total_weighing_heavy', width: 80, title: '过磅重', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'total_number', width: 80, title: '合计支数', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'practical_total_price', width: 80, title: '合计实收', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'kick_back_total', width: 80, title: '佣金', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'is_contract_fees', width: 80, title: '包费用', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'other_tax', width: 88, title: '杂费税率', formatter: ({cellValue}) => {
                            if (this.isBlankVue(cellValue)){
                                return ''
                            }else{
                                return cellValue + '%'
                            }
                        }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'pounds_fees', width: 88, title: '磅费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'wind_cut_fees', width: 88, title: '风割费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'loading_car_fees', width: 88, title: '装车费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'transfer_fees', width: 88, title: '中转费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'freight_fees', width: 88, title: '运费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'procedure_fees', width: 88, title: '手续费', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate,null,null,this.searchAllWarehouse)
                        }
                    },
                    {
                        field:'part_order', width: 80, title: '开单员', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'total_amount', width: 80, title: '合计金额', visible: false, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'cn_state_man', width: 80, title: '出纳审核', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'cn_state_man_date', width: 170, title: '出纳审核时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'handled_by',
                        width: 80,
                        title: '经手人',
                        editRender: {autofocus: 'input.el-input__inner'},
                        slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'last_name_warehouse_bill', width: 90, title: '最后修改人', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'last_date_warehouse_bill', width: 120, title: '最后修改时间', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'creat_date_warehouse_bill', width: 100, title: '记录日期', formatter: ({cellValue}) => {
                            return this.$_formatDate(cellValue, 'yyyy-MM-dd')
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/allBilling/searchHeard', this.pickerDate)
                       }
                   },

                ],
                tableDataMain: [],
                tableDataModel: [],
                createVoucher:{
                    str:'',
                    isShow:false,
                    formData:{
                        voucher_id:'',
                    },
                },
                tableColumnModel: [
                    {field:'is_cancel_name', title: '状态', width: 80},
                    {field:'cash_type_id', title: '单据编码', width: 80},
                    {field:'cash_type_name', width: 122, title: '单据类型'},
                    {field:'year', width: 40, title: '年'},
                    {field:'month', width: 40, title: '月'},
                    {field:'amount', width: 150, title: '金额'},
                    {field:'registrar_cash_data', width: 150, title: '创建人'},
                    {field:'creat_date_cash_data', width: 150, title: '创建日期'},
                    {field:'last_name_cash_data', width: 150, title: '最后修改人'},
                    {field:'last_date_cash_data', width: 150, title: '最后修改日期'},
                ],
                filterData: {
                    bill_id_type: 'KD',
                    customer:'',
               },
                sortData: {
                    bill_date_kd :'asc'
               },
                pickerDate: [],
                pickerDateMonth: '',
           }
       },
        methods: {
            getBillIdList() {
                let list = this.$refs.xGrid.getCheckboxRecords();
                let billList = [];
                if (this.billCategory == 'KD') {
                    for (let i = 0; i < list.length; i++) {
                        billList.push({
                            id: list[i].id
                       });
                   }
               }
                return billList;
           },
            batchCreateVoucher() {
                //先查一遍，再提示
                this.searchAllWarehouse();
                setTimeout(() => {
                    let filterStr = '\n筛选条件：';
                    if (this.filterData != null) {
                        let i = 0;
                        for (let key in this.filterData) {
                            //for循环let key是对象里面的键，再通过,[]的形式this.objNum[item]去获取对象的value值
                            //console.log('key',key);
                            if (i != 0) {
                                filterStr = filterStr + '；'
                           }
                            filterStr = filterStr + this.filterData[key];
                            //console.log('value',this.objNum[key ]);
                            i++
                       }
                   }
                    if (this.tableDataMain.length == 0) {
                        this.$message({
                            showClose: true,
                            message: '筛选范围没有单据',
                            type: 'error'
                       });
                   } else {
                        let str = '将按日期：' + this.$_formatDate(this.pickerDate[0], 'yyyy-MM-dd') + '~' + this.$_formatDate(this.pickerDate[1], 'yyyy-MM-dd') + '\n总(' + this.tablePage.total + ')张单据,生成凭证！(自动跳过已生成凭证的单据)'
                        str = str + filterStr;
                        this.createVoucher.isShow = true
                        this.createVoucher.str = str
                        /*this.$XModal.confirm(str).then(type => {
                            if (type === 'confirm') {
                                this.$_batchCreateVoucher(true,'','');
                           }
                       })*/
                   }
               }, 600)
           },
            changeBillCategoryEvent() {
                this.filterData.bill_id_type = this.billCategory;
                if (this.billCategory == 'KD') {
                    this.tableColumn = this.tableColumnKD;
                    this.sortData = {
                        bill_date_kd :'asc'
                   };
               } else if (this.billCategory == 'JC') {
                    this.tableColumn = this.tableColumnJC;
               } else if (this.billCategory == 'CNDJ') {
                    this.tableColumn = this.tableColumnCNDJ;
               }
                this.searchAllWarehouse();
           },
            openReceipt(row) {
                this.$router.push({
                    path: '/receipt',
                    query: {cash_type_id: row.cash_type_id, year: row.year, month: row.month, type: row.cash_type}
               })
           },

            getNumberDefaultZero(obj) {
                //console.log("getNumberDefaultZero : " + obj);
                if (obj == null || typeof (obj) == 'undefined' || isNaN(obj)) {
                    return Number(0)
               } else {
                    return Number(obj)
               }
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.NumberAdd(count, item[field]);
               })
                return this.getNumberDefaultZero(count)
           },
            cellClassName({column}) {// row,
                if (column.property === 'cash_type_id_list') {
                    return 'col-ableClick'
               }
           },

            //双击提交状态，直接提交当前商品ID，设置对应反选状态
            cellClickEvent({row, column}) {
                if (column.property == 'cash_type_id_list') {
                    this.showModel.bill_id = row.id;
                    this.showModel.isShow = true;
                    this.findCDWBListByBillId();
               }
                //console.log(row,column);
           },
            findCDWBListByBillId() {
                this.$axios({
                    method: 'post',
                    url: '/admin/allWarehouse/findCDWBListByBillId',
                    params: {
                        bill_id: this.showModel.bill_id,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataModel = response.data.list;
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property === 'kick_back' || column.property === 'practical_price'
                            || column.property === 'single_count' || column.property === 'weighing_heavy'
                            || column.property === 'amount_of_product' || column.property === 'practical_total_price'
                            || column.property === 'payend' || column.property === 'balance' || column.property === 'total_amount'
                            || column.property === 'total_weighing_heavy' || column.property === 'pounds_fees' || column.property === 'wind_cut_fees'
                            || column.property === 'loading_car_fees' || column.property === 'transfer_fees' || column.property === 'freight_fees'
                            || column.property === 'procedure_fees'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            searchAllWarehouse() {
                this.tablePage.currentPage = 1;
                this.getAllWarehouse();
           },
            getAllWarehouse() {
                let searchUrl = '/admin/allWarehouse/search'
                if (this.billCategory == 'JC') {
                    this.filterData.bill_id_type = 'JC';
                    searchUrl = '/admin/allBilling/search'
               }else if(this.billCategory == 'KD'){
                    this.filterData.bill_id_type = 'KD';
                    searchUrl = '/admin/allBilling/search'
               }else if(this.billCategory == 'CNDJ'){
                    this.filterData.bill_id_type = '';
                    this.sortData = {};
                    searchUrl = '/admin/allFinance/search';

               }
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        filterData: this.filterData,
                        formData:this.formData,
                        sortData: this.sortData,
                        pickerDate: this.pickerDate,
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    loading.close();
                    if (response.status === 200) {
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.isBuildIng = response.data.isBuildIng
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getAllWarehouse();
           },
            openTheWarehouse(row) {
                if(row.bill_id_type == 'KD'){
                    const{ href} = this.$router.resolve({path: '/transferSlip', query: {bill_id_kd : row.bill_id_kd}});
                    window.open(href,'_blank');
                }else if(row.bill_id_type == 'JC'){
                    const{ href} = this.$router.resolve({path: '/intoTheWarehouse', query: {bill_id : row.bill_id}});
                    window.open(href,'_blank');
               }else if(row.bill_id_type == 'CNDJ'){
                    const{ href} = this.$router.resolve({path: '/receipt', query: { id: row.cash_id}});
                    window.open(href,'_blank');
               }
           },
            printTransferSlip(row) {
                if(row.bill_id_type == 'KD'){
                    const{ href} = this.$router.resolve({path: '/printTransferSlip', query: {bill_id_kd : row.bill_id_kd}});
                    window.open(href,'_blank');
                }else {
                    const{ href} = this.$router.resolve({path: '/printIntoTheWarehouse', query: {bill_id : row.bill_id}});
                    window.open(href,'_blank');
                }
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },

       }, computed: {
       },
        created() {

            this.$nextTick(() => {
                if (!this.isBlankVue(this.$route.query.pickerDate)){
                    //console.log('test')
                    this.pickerDate = this.$route.query.pickerDate
                    this.pickerDateMonth = this.$_formatDate(this.$getMonthFirst(this.$route.query.pickerDate[0]), 'yyyy-MM-dd')
                    this.filterData.customer = this.$route.query.customer
                    this.billCategory = this.$route.query.billCategory
                }else{
                    let current_date = new Date(new Date().getTime() - 3600 * 1000 * 24 * 90);
                    this.pickerDate = [this.$XEUtils.getWhatMonth(current_date, 0, 'first'),this.$XEUtils.getWhatMonth(current_date, 0, 'last')]
                    this.pickerDateMonth = this.pickerDate[0]
                }

                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                //console.log('$refs.xGrid.isAllCheckboxIndeterminate()', this.$refs.xGrid.isAllCheckboxIndeterminate());
                //console.log('$refs.xGrid.isAllCheckboxChecked()', this.$refs.xGrid.isAllCheckboxChecked());


                this.changeBillCategoryEvent();
                this.getAllWarehouse();
           })
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }

    /deep/ .el-input--suffix .el-input__inner {
        padding-right: initial !important;
   }

    /deep/ input[popperclass="myAutocomplete"] {
        padding: 3px !important;
   }

    /deep/ .el-input__suffix {
        right: 0px !important;
   }

</style>
